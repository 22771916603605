<template>
    <Toast />
    <div class="p-grid p-mb-4">
        <div class="p-col-6 p-md-2">
            <span class="p-float-label">
                <InputText style="width: 100%" type="text" v-model="ticketSerial" />
                <label>{{ $t('bets.ticketSerial') }}</label>
            </span>
        </div>
        <div class="p-col-6 p-md-2 p-text-left">
            <Button :label="$t('buttons.getTicket')" class="p-button-raised p-button-warning" @click="showTicketContent()" />
            <Button :label="$t('buttons.clear')" class="p-button-raised p-button-info p-ml-2" @click="clearTicketContent()" />
        </div>
    </div>
    <SportTicketDetails @checkPayment="checkPayment" :forPay="isPay" v-if="isSportsTicket" :ticketSerial="ticketSerial"></SportTicketDetails>
    <LottoTicketDetails @checkPayment="checkPayment" :forPay="isPay" v-if="isLottoTicket" :ticketSerial="ticketSerial"></LottoTicketDetails>
    <RocketTicketDetails @checkPayment="checkPayment" :forPay="isPay" v-if="isRocketTicket" :ticketSerial="ticketSerial"></RocketTicketDetails>
    <VirtualsTicketDetails @checkPayment="checkPayment" :forPay="isPay" v-if="isVirtualsTicket" :ticketSerial="ticketSerial"></VirtualsTicketDetails>
    <div class="p-grid p-mt-4" v-show="showActions" v-if="isCancel">
        <div class="p-col-12 p-md-2">
            <span class="p-float-label">
                <InputText style="width: 100%" type="text" v-model="message" />
                <label>{{ $t('general.message') }}</label>
            </span>
        </div>
        <div class="p-col-6 p-md-2 p-text-left"><Button :label="$t('buttons.save')" :disabled="disableSubmit" class="p-button-raised p-button-success" @click="submitAction()" /></div>
    </div>
    <div class="p-grid p-mt-4" v-show="showActions" v-if="isPay">
        <transition-group name="p-message" v-show="showPayError" tag="div">
            <Message severity="info" :key="paymentErrorCount">{{ paymentErrorMessage }}</Message>
        </transition-group>
        <div class="p-col-12 p-md-2" v-show="okPayment">
            <span class="p-float-label">
                <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                <label>{{ $t('dropdown.placeholder.payShop') }}</label>
            </span>
        </div>
        <div class="p-col-12 p-md-2" v-show="okPayment">
            <span class="p-float-label">
                <InputText style="width: 100%" type="text" v-model="winnerName" />
                <label>{{ $t('bets.winnerName') }}</label>
            </span>
        </div>
        <div class="p-col-12 p-md-2">
            <span class="p-float-label" v-show="okPayment">
                <InputText style="width: 100%" type="text" v-model="winnerSsn" />
                <label>{{ $t('bets.winnerId') }}</label>
            </span>
        </div>
        <div class="p-col-6 p-md-2 p-text-left" v-show="okPayment"><Button :label="$t('buttons.save')" :disabled="disableSubmit" class="p-button-raised p-button-success" @click="submitAction()" /></div>
    </div>
    <div class="p-grid p-mt-4" v-show="showActions" v-if="isSettle">
        <div class="p-col-12 p-md-2">
            <span class="p-float-label">
                <Dropdown :showClear="true" style="width: 100%" v-model="selectedAction" :options="actions" optionLabel="name" :filter="!this.isMobile()" />
                <label>{{ $t('dropdown.placeholder.action') }}</label>
            </span>
        </div>
        <div class="p-col-12 p-md-2" v-show="this.showMessageInput">
            <span class="p-float-label">
                <InputText style="width: 100%" type="text" v-model="message" />
                <label>{{ $t('general.message') }}</label>
            </span>
        </div>
        <div class="p-col-12 p-md-2" v-show="this.showOfferNoInput">
            <span class="p-float-label">
                <InputText style="width: 100%" type="text" v-model="offerNumber" />
                <label>{{ $t('sportsbets.offerNo') }}</label>
            </span>
        </div>
        <div class="p-col-6 p-md-1" v-show="this.showLineStatusInput">
            <div class="p-field-radiobutton p-mt-2">
                <RadioButton id="option5" name="option" value="1" v-model="selectedStatus" />
                <label for="option5">{{ $t('bets.status.winner') }}</label>
            </div>
        </div>
        <div class="p-col-6 p-md-1 p-text-right" v-show="this.showLineStatusInput">
            <div class="p-field-radiobutton p-mt-2">
                <RadioButton id="option6" name="option" value="0" v-model="selectedStatus" />
                <label for="option6">{{ $t('bets.status.lost') }}</label>
            </div>
        </div>
        <div class="p-col-6 p-md-1 p-text-right" v-show="this.showLineStatusInput">
            <div class="p-field-radiobutton p-mt-2">
                <RadioButton id="option6" name="option" value="2" v-model="selectedStatus" />
                <label for="option6">{{ $t('bets.status.pending') }}</label>
            </div>
        </div>
        <div class="p-col-6 p-md-2 p-text-left"><Button :label="$t('buttons.save')" :disabled="disableSubmit" class="p-button-raised p-button-success" @click="submitAction()" /></div>
    </div>
</template>
<script>
import settings from '../../../settings/generalSettings';
import { BoTicketsApi } from '../../../service/BoTicketsApi';
import { BoApi } from '../../../service/BoApi';
import SportTicketDetails from './SportTicketDetails.vue';
import VirtualsTicketDetails from './VirtualsTicketDetails.vue';
import LottoTicketDetails from './LottoTicketDetails.vue';
import RocketTicketDetails from './RocketTicketDetails.vue';

export default {
    name: 'betsOperations',
    components: {
        SportTicketDetails,
        LottoTicketDetails,
        VirtualsTicketDetails,
        RocketTicketDetails,
    },
    data() {
        return {
            BoTicketsApi: new BoTicketsApi(),
            BoApi: new BoApi(),
            ticketSerial: '',
            isSportsTicket: false,
            isVirtualsTicket: false,
            isLottoTicket: false,
            isRocketTicket: false,
            selectedAction: '',
            showActions: false,
            selectedStatus: '2',
            offerNumber: '',
            message: '',
            showMessageInput: false,
            showLineStatusInput: false,
            showOfferNoInput: false,
            disableSubmit: false,
            shops: [],
            shopsModel: {},
            winnerName: '',
            winnerSsn: '',
            okPayment: false,
            paymentErrorCount: 0,
            paymentErrorMessage: '',
            showPayError: false,
        };
    },
    props: {
        operationType: {
            type: String,
        },
    },
    watch: {
        selectedAction() {
            this.showMessageInput = false;
            this.showOfferNoInput = false;
            this.showLineStatusInput = false;
            switch (this.selectedAction.id) {
                case 'voidLine': {
                    this.showOfferNoInput = true;
                    break;
                }
                case 'processLine': {
                    this.showOfferNoInput = true;
                    this.showLineStatusInput = true;
                    break;
                }
                case 'storno': {
                    this.showOfferNoInput = true;
                    break;
                }
                case 'acceptPayment': {
                    this.showMessageInput = true;
                    break;
                }
                case 'rejectPayment': {
                    this.showMessageInput = true;
                    break;
                }
            }
        },
    },
    computed: {
        isPay() {
            return this.operationType == 'pay';
        },
        isCancel() {
            return this.operationType == 'cancel';
        },
        isSettle() {
            return this.operationType == 'settle';
        },
        actions() {
            var actionsArray = [
                { id: 'process', name: this.$t('bets.actions.process') },
                { id: 'void', name: this.$t('bets.actions.void') },
            ];
            if (this.isSportsTicket) {
                actionsArray.push({ id: 'processLine', name: this.$t('bets.actions.processLine') });
                actionsArray.push({ id: 'voidLine', name: this.$t('bets.actions.voidLine') });
            }
            if (this.isLottoTicket) {
                actionsArray.push({ id: 'acceptPayment', name: this.$t('bets.actions.acceptPayment') });
                actionsArray.push({ id: 'rejectPayment', name: this.$t('bets.actions.rejectPayment') });
            }
            return actionsArray;
        },
    },

    mounted() {
        if (this.isPay) {
            this.getShops();
        }
    },
    methods: {
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                    this.createShopsDictionary();
                    this.shops.unshift(this.shopsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        showTicketContent() {
            this.isSportsTicket = false;
            this.isLottoTicket = false;
            this.isVirtualsTicket = false;
            this.isRocketTicket = false;
            if (this.notNullOrEmptyField(this.ticketSerial)) {
                if (this.ticketSerial.length > 1) {
                    var ticketType = this.ticketSerial.substring(0, 2);
                    switch (ticketType) {
                        case settings.ticketType.sportBets: {
                            this.isSportsTicket = true;
                            this.showActions = true;
                            break;
                        }
                        case settings.ticketType.onlineSB: {
                            this.isSportsTicket = true;
                            this.showActions = true;
                            break;
                        }
                        case settings.ticketType.lotto: {
                            this.isLottoTicket = true;
                            this.showActions = true;
                            break;
                        }
                        case settings.ticketType.keno: {
                            this.isVirtualsTicket = true;
                            this.showActions = true;
                            break;
                        }
                        case settings.ticketType.roulette: {
                            this.isVirtualsTicket = true;
                            this.showActions = true;
                            break;
                        }
                        case settings.ticketType.ballSix: {
                            this.isVirtualsTicket = true;
                            this.showActions = true;
                            break;
                        }
                        case settings.ticketType.rocket: {
                            this.isRocketTicket = true;
                            this.showActions = true;
                            break;
                        }
                        default: {
                            this.showToast('error', this.$t('bets.alerts.invalidSerial'));
                        }
                    }
                } else {
                    this.showToast('error', this.$t('bets.alerts.invalidSerial'));
                }
            } else {
                this.showToast('error', this.$t('bets.alerts.emptySerial'));
            }
        },
        clearTicketContent() {
            this.ticketSerial = '';
            this.isSportsTicket = false;
            this.isRocketTicket = false;
            this.isLottoTicket = false;
            this.isVirtualsTicket = false;
            this.showActions = false;
            this.selectedAction = '';
            this.offerNumber = '';
            this.message = '';
            this.selectedStatus = '2';
        },
        submitAction() {
            var actionDetails = '';
            var actionFieldsCompleted = true;
            var paymentShop = '';
            if (this.isCancel) {
                this.selectedAction = { id: 'storno' };
            } else {
                if (this.isPay) {
                    this.selectedAction = { id: 'pay' };
                    actionDetails = 'BO payments';
                    if (this.notNullOrEmptyObject(this.shopsModel)) {
                        paymentShop = this.shopsModel.id;
                    } else {
                        this.showToast('error', this.$t('bets.alerts.settleNoShop'));
                        actionFieldsCompleted = false;
                    }
                } else {
                    if (this.notNullOrEmptyObject(this.selectedAction)) {
                        var action = this.selectedAction.id;
                        if ((action == 'voidLine' || action == 'processLine') && this.offerNumber == '') {
                            this.showToast('error', this.$t('bets.alerts.settleLineNoOfferNo'));
                            actionFieldsCompleted = false;
                        } else {
                            if (action == 'acceptPayment' || action == 'rejectPayment') {
                                actionDetails = this.message;
                            }
                            if (action == 'voidLine' || action == 'processLine') {
                                actionDetails = this.offerNumber;
                            }
                        }
                    } else {
                        this.showToast('error', this.$t('bets.alerts.settleNoAction'));
                        actionFieldsCompleted = false;
                    }
                }
            }
            if (actionFieldsCompleted) {
                var actionDetailsObj = {
                    TicketSerial: this.ticketSerial,
                    Action: this.selectedAction.id,
                    Details: actionDetails,
                    NewStatus: this.selectedStatus,
                    ShopId: paymentShop,
                    WinnerName: this.winnerName,
                    WinnerSsn: this.winnerSsn,
                };
                this.disableSubmit = true;
                this.BoTicketsApi.ticketAction(actionDetailsObj)
                    .then(() => {
                        this.disableSubmit = false;
                        this.showToast('success', this.$t('alerts.okAction'));
                        this.clearTicketContent();
                    })
                    .catch((error) => {
                        this.disableSubmit = false;
                        this.checkForUnauthorizedError(error);
                        if (error.response.data.Message != null && error.response.data.Message != 'false') {
                            let errorResponse = JSON.parse(error.response.data.Message);
                            let errorText = errorResponse['message'];
                            if (this.isPay) {
                                errorText = errorResponse['paidMessage'];
                            }
                            this.showToast('error', errorText);
                        } else {
                            this.showToast('error', this.$t('alerts.generalError'));
                        }
                    });
            }
        },
        checkPayment(status) {
            this.showPayError = false;
            if (status == 1 || status == -1) {
                this.okPayment = true;
            } else {
                this.okPayment = false;
                var statusMessage = 'error';
                switch (status) {
                    case 2: {
                        statusMessage = 'Pending ticket.';
                        break;
                    }
                    case 0: {
                        statusMessage = 'Lost ticket.';
                        break;
                    }
                    case 3: {
                        statusMessage = 'Paid ticket.';
                        break;
                    }
                    case 5: {
                        statusMessage = 'Cancelled ticket.';
                        break;
                    }
                    case 4: {
                        statusMessage = 'Expired ticket.';
                        break;
                    }
                }
                this.paymentErrorMessage = "Ticket can't be paid: " + statusMessage;
                this.paymentErrorCount++;
                this.showPayError = true;
            }
        },
    },
};
</script>

